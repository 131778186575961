import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'replyTo',
    'differentReplyTo',
    'delayDays',
    'deliverAfterDeparture'
  ]

  connect() {
    this.toggleReplyTo({ currentTarget: this.differentReplyToTarget });
  }

  toggleReplyTo(e) {
    if (e.currentTarget.checked) {
      this.replyToTarget.classList.remove('hidden');
    } else {
      this.replyToTarget.classList.add('hidden');
      this.replyToTarget.querySelector('input').value = '';
    }
  }

  toggleDeparture(e) {
    if (this.deliverAfterDepartureTarget.value === 'true') {
      this.delayDaysTarget.classList.remove('hidden');
    } else {
      this.delayDaysTarget.classList.add('hidden');
    }
  }
}