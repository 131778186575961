import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'container', 'errorSection' ]

  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get('url'))
      .then(response => response.text())
      .then(html => {
        $(this.containerTarget).html(html)
      })
  }

  handleError(event) {
    const xhr = event.detail[2];
    $(this.errorSectionTarget).html(xhr.responseText)
  }
}
