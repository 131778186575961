import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'pmsTile', 'wifiGuestDataTile', 'preArrivalDataTile' ]

  connect() {
    this.startPolling()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 3000)
  }

  poll() {
    const url = this.data.get('url')
    
    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (
          json.pms_tile.has_previous_historical_imports.toString() !== this.pmsTileTarget.dataset.hasPreviousHistoricalImports ||
          json.pms_tile.active_historical_pms_import.toString() !== this.pmsTileTarget.dataset.activeHistoricalPmsImport
        ) {
          this.pmsTileTarget.outerHTML = json.pms_tile.html
        }

        if (
          json.wifi_guest_data_tile.email_marketing_guest_backfill.toString() !== this.wifiGuestDataTileTarget.dataset.emailMarketingGuestBackfill
        ) {
          this.wifiGuestDataTileTarget.outerHTML = json.wifi_guest_data_tile.html
        }

        if (
          ((json.pre_arrival_data_tile.email_marketing_guest_backfill && json.pre_arrival_data_tile.email_marketing_guest_backfill.toString()) !== this.preArrivalDataTileTarget.dataset.emailMarketingGuestBackfill)
        ) {
          this.preArrivalDataTileTarget.outerHTML = json.pre_arrival_data_tile.html
        }
      })
  }
}